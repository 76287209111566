$person-card-md-breakpoint: 785px;

.grid-container {
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 40px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  justify-content: center;
  width: 100%;

  @media (max-width: $mobile-breakpoint) { 
    grid-row-gap: 32px;
    grid-template-columns: repeat(1, 1fr);
  }

  div {
    grid-column: span 4;

    @media (max-width: $mobile-breakpoint) {
      grid-column: span 1;
    }
  }
}

.person-card-grid--big {
  @extend .grid-container;

  @media (max-width: $mobile-breakpoint) { 
    grid-row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  div {
    grid-column: span 3;

    @media (max-width: $person-card-md-breakpoint) {
      grid-column: span 6;
    }

    @media (max-width: $mobile-breakpoint) {
      grid-column: span 2;
    }
  }
}

.person-card-grid--small {
  @extend .person-card-grid--big;

  div {
    grid-column: span 3;
    
    @media (max-width: $mobile-breakpoint) {
      grid-column: span 1;
    }
  }
}
