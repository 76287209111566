.hero-banner-container {
  background-image: url('~@public-web/assets/images/hero-banner-bg.png');
  background-position: 80% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 0 $dsp24;

  @media (max-width: $mobile-breakpoint) {
    background-position: 80% 112%;
  }

  .hero-banner {
    display: flex;
    max-width: $max-container-width;
    overflow-x: hidden;
    width: 100%;

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }
  }

  .hero-banner-text {
    padding-top: 80px;

    @media (max-width: $mobile-breakpoint) {
      padding-top: 56px;
    }

    @media (min-width: $desktop-start) {
      width: 80%;
    }
  }

  img {
    align-self: flex-end;
    height: 402px;
    max-width: 432px;
    padding-top: 80px;

    @media (max-width: $mobile-breakpoint) {
      height: 327px;
      max-width: 352px;
      padding-right: 0;
      padding-top: 0;
    }
  }
}
