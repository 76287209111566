.section {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.section > div {
  box-sizing: border-box;
  padding: 0 $dsp24;
  width: 100%;

  &.narrow {
    max-width: 976px;
  }

  &.wide {
    max-width: $max-container-width;
  }
}
  
.section-title {
  text-align: center;
}
