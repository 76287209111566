@font-face {
  font-family: 'Larsseit-Bold';
  src: url('~@public-web/assets/fonts/Larsseit-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Larsseit-Light';
  src: url('~@public-web/assets/fonts/Larsseit-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Larsseit-Medium';
  src: url('~@public-web/assets/fonts/Larsseit-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Larsseit';
  src: url('~@public-web/assets/fonts/Larsseit.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-Bold';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-BoldItalic';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-ExtraBold';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-ExtraBoldItalic';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-Light';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-LightItalic';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-Medium';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-MediumItalic';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-Regular';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-RegularItalic';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-Thin';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Larsseit-ThinItalic';
  src: url('~@public-web/assets/fonts/SVN/SVN-Larsseit-ThinItalic.ttf') format('truetype');
}

.h1 {
  font-family: 'SVN-Larsseit-Bold', 'Larsseit-Bold', sans-serif;
  font-size: 61px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 32px;
  }
}

.h2 {
  font-family: 'SVN-Larsseit-Bold', 'Larsseit-Bold', sans-serif;
  font-size: 49px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 29px;
  }
}

.h3 {
  font-family: 'SVN-Larsseit-Bold', 'Larsseit-Bold';
  font-size: 39px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 26px;
  }
}

.h4 {
  font-family: 'SVN-Larsseit-Bold', 'Larsseit-Medium';
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.h5 {
  font-family: 'SVN-Larsseit-Bold', 'Larsseit-Medium';
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 20px;
  }
}

.h6 {
  font-family: 'SVN-Larsseit-Bold', 'Larsseit-Medium';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 18px;
  }
}

.large {
  font-family: 'SVN-Larsseit-Regular', 'Larsseit';
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 18px;
  }
}

.base {
  font-family: 'SVN-Larsseit-Regular', 'Larsseit';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.small {
  font-family: 'SVN-Larsseit-Regular', 'Larsseit';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 14px;
  }
}

.smallest {
  font-family: 'SVN-Larsseit-Regular', 'Larsseit';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 13px;
  }
}
