.statistic-card {
  text-align: center;
}

.category-card {
  text-align: center;

  @media (max-width: $mobile-breakpoint) {
    display: flex;
    text-align: left;
  }

  img {
    border-radius: 50%;
    height: 120px;
    width: 120px;

    @media (max-width: $mobile-breakpoint) {
      height: 96px;
      width: 96px;
    }
  }
}

.person-card {
  text-align: center;

  .image-container--big {
    border-radius: 50%;
    height: 140px;
    width: 140px;

    @media (max-width: $mobile-breakpoint) {
      height: 123px;
      width: 123px;
    }
  }

  .image-container--small {
    border-radius: 50%;
    height: 120px;
    width: 120px;

    @media (max-width: $mobile-breakpoint) {
      height: 96px;
      width: 96px;
    }
  }
}

.list-item-card {
  display: flex;
  
  img {
    height: 64px;
    width: 64px;

    @media (max-width: $mobile-breakpoint) {
      height: 40px;
      width: 40px;
    }
  }
}
