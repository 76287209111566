@import 'swiper/swiper';
@import './variables';
@import './utilities';
@import './components/typography';
@import './components/accordion';
@import './components/check-box';
@import './components/drop-down';
@import './components/text-area';
@import './components/text-field';
@import './components/pull-quote';
@import './components/card';
@import './components/button-link';
@import './components/slider';
@import './components/row';
@import './components/bread-crumb';
@import './components/testimonial';
@import './components/hero-banner';
@import './components/cta';
@import './components/info-banner';
@import './components/search-input';
@import './components/feature-highlights';
@import './components/link';
@import './components/section';
@import './components/search-header';
@import './components/text-icon-group';
@import './components/success-template';
@import './components/logo-section';
@import './components/grid';
@import './components/lead-gen-form';


* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}
