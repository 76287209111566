.testimonial {
  .swiper-slide-next {
    opacity: 0.4;

    @media (max-width: $mobile-breakpoint) {
      opacity: 0;
    }
  }

  .testimonial-item-info {
    display: flex;

    .image-desktop-only {
      @media (min-width: $desktop-start) {
        display: flex;
      }
    }

    img {
      height: 256px;
      width: 256px;

      @media (max-width: $mobile-breakpoint) {
        height: 80px;
        width: 80px;
      }
    }

    .testimonial-text {
      max-width: 736px;
    }

    .testimonial-description-mobile {
      @media (max-width: $mobile-breakpoint) {
        display: flex;
      }
    }
  }

  .testimonial-dots {
    display: flex;
    justify-content: center;

    .testimonial-dot {
      background-color: $lightGray300;
      border-radius: 50%;
      height: 12px;
      width: 12px;
    }

    .active {
      background-color: $indigo400;
    }
  }
}
