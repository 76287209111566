.logo-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 56px;
  justify-content: space-around;

  @media (max-width: $mobile-breakpoint) {
    gap: 24px 20px;
  }

  .logo {
    display: flex;
    max-width: 236px;

    @media (max-width: $mobile-breakpoint) {
      max-width: 135px;
    }

    img {
      height: 48px;
      width: 100%;

      @media (max-width: $mobile-breakpoint) {
        height: 44px;
      }
    }
  }
}
